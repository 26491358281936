import React, { useEffect, useState } from "react";
import CustomButton from "../CustomButton/CustomButton";
import styles from "./Results.module.css";

function Results({ result, question, isCorrectRequest, isUpdateRequest, backAction, correctAction, saveAction, isUpdated }) {
  const [isWrong, setIsWrong] = useState(false);
  const [correctResult, setCorrectResult] = useState(result);

  useEffect(() => {
    if (isUpdated) setIsWrong(false);
  }, [isUpdated]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.questionHeader}>Question</div>
      <div className={styles.question}>{question}</div>
      {isWrong ? (
        <>
          <div className={styles.editAnswerHeader}>
            Type your answer here:
            <input
              type="hidden"
              name="question"
              value={question || ""}
            />
            <textarea className={styles.editAnswer} value={correctResult} onChange={(e) => setCorrectResult(e.target.value)} />
          </div>
          <div className={styles.buttons}>
            <CustomButton text={isUpdateRequest ? "Processing...." : "Update answer"} width={"237px"} clickAction={() => saveAction(correctResult, question)} />
          </div>
        </>
      ) : (
        <>
          <div className={isUpdated ? `${styles.answerHeader} ${styles.answerUpdated}` : styles.answerHeader}>
            Answer
            <div className={styles.answer}>{result}</div>
          </div>
          {isUpdated ? null : (
            <div className={styles.buttons}>
              <CustomButton text={isCorrectRequest ? "Processing...." : "Correct"} width={"184px"} clickAction={correctAction} />
              <CustomButton text={"Wrong"} width={"184px"} clickAction={() => setIsWrong(true)} color="red" />
            </div>
          )}
        </>
      )}
      <CustomButton text={"Back to main menu"} max-width={"383px"} width={"100%"} className={styles.backBtn} clickAction={backAction} />
    </div>
  );
}

export default Results;
