import React from "react";
import styles from "./CustomButton.module.css";

function CustomButton({ text, width, clickAction, color = "green" }) {
  return (
    <div
      className={color === "green" ? `${styles.wrapper} ${styles.green}` : `${styles.wrapper} ${styles.red}`}
      style={{ width }}
      onClick={clickAction}
    >
      {text}
    </div>
  );
}

export default CustomButton;
