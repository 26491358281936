import React, { useEffect, useState } from "react";
import CustomButton from "../CustomButton/CustomButton";
import styles from "./TopQuestions.module.css";

function TopQuestions({ questions, clickAction }) {
  const [count, setCount] = useState(5);
  const [isClick, setIsClick] = useState(false);
  const [checkedId, setCheckedId] = useState(null);

  const showMoreHandler = () => {
    setCount((prevCount) => (prevCount += 5));
  };

  const clickHandler = (question, id) => {
    setIsClick(true);
    setCheckedId(id);
    clickAction(question);
  };

  useEffect(() => {
    if (isClick) {
      setTimeout(() => {
        setIsClick(false);
      }, 2000);
    }
  }, [isClick]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.questions}>
        <div className={styles.header}>Top questions</div>
        <div className={styles.columnsHeader}>
          Question <span>Times</span>
        </div>
        {questions
          ?.sort((a, b) => {
            if (a.times < b.times) return 1;
            else return -1;
          })
          .map((el, i) => {
            const { text, times, id } = el;
            if (i < count) {
              return (
                <div
                  key={id +" " + i}
                  className={isClick && id === checkedId ? `${styles.question} ${styles.active}` : styles.question}
                  onClick={() => clickHandler(text, id +" " + i)}
                >
                  <div className={styles.question_text}>
                    {text}
                  </div>
                  <span>{times}</span>
                </div>
              );
            } else return null;
          })}
      </div>
      {count < questions?.length ? <CustomButton text={"Show more"} clickAction={showMoreHandler} width="244px" /> : null}
    </div>
  );
}

export default TopQuestions;
