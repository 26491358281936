import { useEffect, useState } from "react";
import { getTopQuestions } from "./api/getTopQuestions";
import { correctAnswer } from "./api/correctAnswer";
import { updateAnswer } from "./api/updateAnswer";
import { getAnswer } from "./api/getAnswer";
import styles from "./App.module.css";
import CustomButton from "./components/CustomButton/CustomButton";
import Results from "./components/Results/Results";
import TopQuestions from "./components/TopQuestions/TopQuestions";
// import logo from "./img/dog.png";

function App() {
  const [animalInput, setAnimalInput] = useState(null);
  // const [wordsInput, setWordsInput] = useState("");
  const [result, setResult] = useState(null);
  const [topQuestions, setTopQuestions] = useState(null);
  const [isAnswerRequest, setIsAnswerRequest] = useState(false);
  const [isCorrectRequest, setIsCorrectRequest] = useState(false);
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    getTopQuestions().then(setTopQuestions);
  }, []);

  const onSubmit = (value) => {
    if (value) {
      setIsAnswerRequest(true);
      getAnswer(value).then((response) => {
        setIsAnswerRequest(false);
        setResult(response.result);

        
        
        getTopQuestions().then(setTopQuestions);
        window.scrollTo(0, 0);
        
      });
    }
  };

  const backHandler = () => {
    setResult(null);
    setAnimalInput(null);
    setIsUpdated(false);
  };

  const correctHandler = () => {
    setIsCorrectRequest(true);
    correctAnswer().then((response) => {
      if (response.status === "OK") {
        setResult(null);
        setAnimalInput(null);
        setIsCorrectRequest(false);
      }
    });
  };

  const updateHandler = (result, question) => {
    setIsUpdateRequest(true);
    //console.log("update handler", result, question);
    updateAnswer(result, question).then((response) => {
      if (response.status === "OK") {
        setIsUpdated(true);
        setIsUpdateRequest(false);
        setResult(response.result);
      }
    });
  };

  const clickQuestionHandler = (question) => {
    backHandler();
    setAnimalInput(question);
    onSubmit(question);
  };

  return (
    <div className={styles.wrapper}>
      <main className={styles.main}>
        {/* <img src={logo} className={styles.icon} alt="logo" /> */}
        <h3>Antler Knowledge Base</h3>
        {result ? (
          <div className={styles.result}>
            <Results
              result={result}
              question={animalInput}
              isCorrectRequest={isCorrectRequest}
              isUpdateRequest={isUpdateRequest}
              backAction={backHandler}
              correctAction={correctHandler}
              saveAction={updateHandler}
              isUpdated={isUpdated}
            />
          </div>
        ) : (
          <form onSubmit={() => onSubmit(animalInput)}>
            <input
              className={styles.main__input}
              type="text"
              name="animal"
              placeholder="Enter the question"
              value={animalInput || ""}
              onChange={(e) => setAnimalInput(e.target.value)}
            />
            {/* <input type="text" name="words" placeholder="how many words" value={wordsInput} onChange={(e) => setWordsInput(e.target.value)} /> */}
            <CustomButton text={isAnswerRequest ? "Processing...." : "Receive answer"} clickAction={() => onSubmit(animalInput)} width="244px" />
          </form>
        )}
      </main>
      <div className={styles.questions}>
        <TopQuestions questions={topQuestions} clickAction={clickQuestionHandler} />
      </div>
    </div>
  );
}

export default App;
